import React from "react"
import { PageTitle, PageLayout } from "../components"

export default () => {
  return (
    <PageLayout>
      <PageTitle>404: Not Found</PageTitle>
    </PageLayout>
  )
}
